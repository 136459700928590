// import _ from "lodash";
import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useMemo,
  useRef,
} from 'react';
import { Typography, useTheme, Alert } from '@mui/material';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import { BsRow, BsCol } from '../../../layouts/components';
import {
  DataTableWidget,
  FilterColumnTableWidget,
  HighchartsWrapper,
  LoadingWidget,
} from '../../../components';
import { AxiosContext } from '../../../context/AxiosContext';
import {
  alterArrayForCountData,
  alterArrayGetIds,
  dashDelete,
  dateTimeParse,
  dateTimeToCalendarFormat,
  IsDesktopHandler,
  numberFormat,
  numberFormatDecimals,
} from '../../../helpers';
import { FiltersContext } from '../../../context';
import { Context } from '../../../context/Context';
import { useErrorHandler } from 'react-error-boundary';
import { varietyGraphColors } from 'theme/palette';

const useStyles = makeStyles((theme) => ({}));

// const MOCK_DATA = [
//   {
//     variety: 'Bing',
//     varietyValue: 'Bing',
//     dailyKilos: [
//       {
//         x: '2023-05-25',
//         y: 18,
//       },
//       {
//         x: '2023-05-26',
//         y: 2,
//       },
//       {
//         x: '2023-05-29',
//         y: 16,
//       },
//       {
//         x: '2023-05-30',
//         y: 19,
//       },
//       {
//         x: '2023-05-31',
//         y: 45,
//       },
//       {
//         x: '2023-06-01',
//         y: 40,
//       },
//     ],
//   },
//   {
//     variety: 'Santina',
//     varietyValue: 'Santina',
//     dailyKilos: [
//       {
//         x: '2023-05-25',
//         y: 12,
//       },
//       {
//         x: '2023-05-26',
//         y: 12,
//       },
//       {
//         x: '2023-05-29',
//         y: 8,
//       },
//       {
//         x: '2023-05-30',
//         y: 9,
//       },
//       {
//         x: '2023-05-31',
//         y: 16,
//       },
//       {
//         x: '2023-06-01',
//         y: 24,
//       },
//     ],
//   },
// ];

// const MOCK_DATA2 = {
//   varieties: ['Bing', 'Santina', 'Lapins', 'Sweetheart', 'Stella'],
//   data: [57.67, 0, 41.6, 27.13, 873.71],
//   totalHarvest: 2456.78,
// };

Highcharts.setOptions({
  lang: {
    loading: 'Cargando...',
    months: [
      'Enero',
      'Febrero',
      'Marzo',
      'Abril',
      'Mayo',
      'Junio',
      'Julio',
      'Agosto',
      'Septiembre',
      'Octubre',
      'Noviembre',
      'Diciembre',
    ],
    weekdays: [
      'Domingo',
      'Lunes',
      'Martes',
      'Miércoles',
      'Jueves',
      'Viernes',
      'Sábado',
    ],
    shortMonths: [
      'Ene',
      'Feb',
      'Mar',
      'Abr',
      'May',
      'Jun',
      'Jul',
      'Ago',
      'Sep',
      'Oct',
      'Nov',
      'Dic',
    ],
    exportButtonTitle: 'Exportar',
    printButtonTitle: 'Importar',
    rangeSelectorFrom: 'Desde',
    rangeSelectorTo: 'Hasta',
    rangeSelectorZoom: 'Período',
    downloadPNG: 'Descargar imagen PNG',
    downloadJPEG: 'Descargar imagen JPEG',
    downloadPDF: 'Descargar imagen PDF',
    downloadSVG: 'Descargar imagen SVG',
    printChart: 'Imprimir',
    resetZoom: 'Reiniciar zoom',
    resetZoomTitle: 'Reiniciar zoom',
    thousandsSep: '.',
    decimalPoint: ',',
  },
});

const generalOptionsHighCharts = {
  credits: {
    enabled: false,
  },
  accessibility: {
    enabled: false,
  },
  responsive: {
    rules: [
      {
        condition: {
          maxWidth: 580,
        },
        chartOptions: {
          legend: {
            layout: 'horizontal',
            align: 'left',
            verticalAlign: 'bottom',
          },
        },
      },
    ],
  },
};

const endPointName = 'v1/planningHarvest/dashboard';

const COLORS_SERIES = [
  '#7cb5ec',
  '#434348',
  '#90ed7d',
  '#f7a35c',
  '#8085e9',
  '#f15c80',
  '#e4d354',
];

const convertDataIntoObjectWithColor = (data) => {
  return data.map((item, index) => {
    return {
      y: item,
      color: COLORS_SERIES[index],
    };
  });
};

const DashboardContent = ({ filter: filterProp }) => {
  const theme = useTheme();

  const { userOrchard } = useContext(Context);

  const chartRef = useRef(null);
  const chartStockRef = useRef(null);

  const errorHandler = useErrorHandler();

  const [loadingGraphState, setLoadingGraphState] = useState(false);
  const [graphDataState, setGraphDataState] = useState(null);
  const [graphOptionsState, setGraphOptionsState] = useState(null);

  // console.log(theme);

  const classes = useStyles();

  const graphStockOptions = {
    chart: {
      alignTicks: false,
      height: 400,
      zoomType: 'y',
      spacingBottom: 8,
      spacingLeft: 10,
      spacingRight: 20,
      spacingTop: 10,
    },

    time: {
      timezone: 'America/Santiago',
    },

    rangeSelector: {
      selected: 1,
      enabled: false,
    },

    title: {
      text: '',
    },
    credits: {
      enabled: false,
    },
    // xAxis: {
    // 	categories: ['Pre Poda', 'Post Poda'],
    // 	crosshair: false,
    // },
    colors: varietyGraphColors,
    series: graphOptionsState?.series || [],
    tooltip: {
      shared: false,
      split: true,
      useHTML: true,
      style: {
        width: '190px',
      },
      pointFormat:
        '{series.name}: <b>{point.y}</b><br/><span style="fontSize: 11px">Agricola/s: {point.agroBusiness}</span>',
      valueSuffix: ' kg',
    },
    legend: {
      align: 'center',
      className: 'legend_class',
      useHTML: true,
      enabled: true,
      verticalAlign: 'top',
      // backgroundColor: 'tomato',
    },
    plotOptions: {
      series: {
        events: {
          legendItemClick: function (e) {
            let series = this.chart.series,
              index = this.index;

            const resultCols = series.filter(
              (ele) => ele.initialType === 'column'
            );

            if (this.visible) {
              for (let i = 0; i < resultCols.length; i++) {
                if (resultCols[i].name === this.name) {
                  resultCols[i].update({ visible: true });
                } else {
                  resultCols[i].update({ visible: false });
                }
              }
            } else {
              for (let i = 0; i < resultCols.length; i++) {
                resultCols[i].update({ visible: true });
              }
            }
            return false;
          },
        },
      },
    },
  };

  const axiosContext = useContext(AxiosContext);

  const isDesktop = IsDesktopHandler('md');

  const { state: filterState } = useContext(FiltersContext);

  const optionsHighChartsBarHarvest = {
    ...generalOptionsHighCharts,
    title: {
      text: '',
    },
    chart: {
      type: 'column',
      height: 300,
      zoomType: 'y',
      spacingBottom: 8,
      spacingLeft: 0,
      spacingRight: 0,
      spacingTop: 10,
    },
    tooltip: {
      // valueSuffix: '',
      // pointFormat: '<b>{point.y:.2f}</b>',
      formatter: function () {
        return '<b>' + numberFormat(this.y) + ' kg.</b><br/>' + this.x;
      },
    },
    xAxis: {
      categories:
        graphDataState && graphDataState.harvestVarieties.varieties
          ? graphDataState.harvestVarieties.varieties
          : [],
      crosshair: true,
    },
    yAxis: {
      min: 0,
      gridLineWidth: 1,
      tickPixelInterval: 20,
      title: {
        text: 'Cosecha (Kg)',
      },
    },
    legend: {
      enabled: false,
    },
    colors: varietyGraphColors,
    series: [
      {
        name: 'Cosecha',
        data:
          graphDataState && graphDataState.harvestVarieties.data
            ? convertDataIntoObjectWithColor(
                graphDataState.harvestVarieties.data
              )
            : [],
      },
    ],
  };

  async function queryData() {
    setLoadingGraphState(true);
    try {
      // console.log("llega la data",limitState, "----",pageState,"---",filterProp);

      const {
        orchard,
        variety,
        sinceDate,
        untilDate,
        specie,
        productionLine,
        technicBoss,
        group,
        // temporada,
      } = filterProp;

      // console.log(filterProp, '-----filterProp');

      const objQuery = {
        orchardValue: orchard,
        // huerto_user: userOrchard, // orchard del login
        technicBossValue: technicBoss?.value || '',
        groupValue: group?.value || '',
        varietyValue: variety?.value || '',
        specieValue: specie?.value || '',
        productLineValue: productionLine?.value || '',
        dateSince: sinceDate,
        dateUntil: untilDate,
        // season: temporada,
      };

      // const paramsQuery = {
      //   params: objQuery,
      // };

      // console.log(objQuery, '--------objQuery');

      const response = await axiosContext.authAxios.post(
        endPointName,
        objQuery
      );

      const { data, status } = response;

      // console.log(response, 'response');

      if (status === 200) {
        // console.log(data, 'data');

        setGraphDataState(data);
        setLoadingGraphState(false);
      }
    } catch (err) {
      setGraphDataState([]);
      setLoadingGraphState(false);
      errorHandler(err);
    } finally {
      setLoadingGraphState(false);
    }
    // setGraphDataState({
    //   totalKilos: MOCK_DATA,
    //   harvestVarieties: MOCK_DATA2,
    // });
  }

  useEffect(() => {
    if (filterProp) {
      console.log(filterProp);
      queryData();
    }
  }, [filterProp]);

  useEffect(() => {
    if (graphDataState) {
      // console.log(graphDataState, 'empieza a crear gráfico');

      const seriesTotalKilos = graphDataState.totalKilos.map((item) => {
        return {
          type: 'column',
          name: item.variety,
          showInLegend: true,
          data: alterArrayForCountData(item.dailyKilos),
          dataGrouping: {
            units: [['week', [1]]],
            // forced: true,
            // enabled: true,
            // groupAll: true,
          },
        };
      });

      // console.log(seriesTotalKilos, 'seriesTotalKilos');

      setGraphOptionsState({
        series: seriesTotalKilos,
      });
    }
  }, [graphDataState]);

  const openFullscreen = (openRef) => {
    console.log(openRef.current, 'chartRef.current');
    if (openRef.current) {
      openRef.current.chart.fullscreen.open();
    } else {
      console.warn(
        'El modo de pantalla completa no está soportado por este gráfico.'
      );
    }
  };

  if (loadingGraphState) {
    return <LoadingWidget />;
  }

  return (
    <>
      {graphDataState ? (
        <>
          <BsRow class='mb-5'>
            <BsCol class='col-12'>
              {graphDataState.harvestVarieties.totalHarvest === 0 ? (
                <Alert severity='info'>
                  No se encontraron registros en la{' '}
                  <b>Planificación Bi Semanal</b>.
                </Alert>
              ) : null}
              <HighchartsWrapper
                fullScreenHandler={() => openFullscreen(chartRef)}
                title='Cosecha Por Variedad'
              >
                <BsRow>
                  <BsCol class='col-md-12 mt-2 text-right'>
                    <Typography variant='h5' gutterBottom>
                      Total Cosecha{' '}
                      {numberFormatDecimals(
                        graphDataState.harvestVarieties.totalHarvest,
                        1
                      )}{' '}
                      Kg.
                    </Typography>
                  </BsCol>
                </BsRow>
                {graphDataState.harvestVarieties.totalHarvest === 0 ? (
                  <Alert severity='info'>
                    No se encontraron registros en la{' '}
                    <b>Planificación Bi Semanal</b>.
                  </Alert>
                ) : null}
                <HighchartsReact
                  highcharts={Highcharts}
                  options={optionsHighChartsBarHarvest}
                  ref={chartRef}
                />
              </HighchartsWrapper>
            </BsCol>
          </BsRow>
          <BsRow class=''>
            <BsCol class='col-12'>
              <HighchartsWrapper
                fullScreenHandler={() => openFullscreen(chartStockRef)}
                title=' Total Acumulado'
              >
                <HighchartsReact
                  highcharts={Highcharts}
                  options={graphStockOptions}
                  constructorType={'stockChart'}
                  ref={chartStockRef}
                />
              </HighchartsWrapper>
            </BsCol>
          </BsRow>
        </>
      ) : null}
    </>
  );
};

export default DashboardContent;
