import React from 'react';
import { Link, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { BsContainer, BsRow, BsCol } from './layouts/components';
import classes from './views/NotFound/NotFound.module.scss';

const ErrorView = () => {
	// const preventDefault = (event) => event.preventDefault();
	return (
		<div
			className={classes.root}
			style={{
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				height: '100vh',
			}}
		>
			<BsContainer fluid>
				<BsRow class="justify-content-md-center">
					<BsCol class="col-lg-6 mt-4">
						<div className={classes.content}>
							<Typography variant="h1" gutterBottom>
								Ha ocurrido un error.
							</Typography>
							<Typography variant="subtitle1">
								Puede probar volver hacia atrás o{' '}
								<Link href="./" style={{ fontWeight: 'bold' }}>
									click aquí
								</Link>{' '}
								para ir al home.
							</Typography>
						</div>
					</BsCol>
				</BsRow>
			</BsContainer>
		</div>
	);
};

export default ErrorView;
