export const tabItems = [
  {
    id: '64234c69e87922175a9d3d2b',
    name: 'Days',
    namePrint: 'Conteos diarios dardos/ramillas',
    code: 'counter-process-002',
    order: 1,
    idModule: '64234ac2f7b53d43704e067b',
  },
  {
    id: '64234c060138091f451d490c',
    name: 'PlantDays',
    namePrint: 'Conteos diarios frutos',
    code: 'counter-process-001',
    order: 2,
    idModule: '64234ac2f7b53d43704e067b',
  },
  {
    id: '64234c9a7cd3413ad1c73c6e',
    name: 'Quarter',
    namePrint: 'Promedios por cuartel',
    code: 'counter-process-003',
    order: 3,
    idModule: '64234ac2f7b53d43704e067b',
  },
  {
    id: '64234e0abce53eefcb15fe72',
    name: 'Plant',
    namePrint: 'Conteos por planta',
    code: 'counter-process-004',
    order: 4,
    idModule: '64234ac2f7b53d43704e067b',
  },
  {
    id: '650af9fc905e5a1129142034',
    name: 'PlantHeatMap',
    namePrint: 'Mapa de Conteos',
    code: 'counter-process-005',
    order: 5,
    idModule: '64234ac2f7b53d43704e067b',
  },
];
