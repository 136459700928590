import React, { useState, useContext, useEffect } from 'react';
import _ from 'lodash';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import TagManager from 'react-gtm-module';
import { useTheme, makeStyles, ThemeProvider } from '@mui/styles';
import {
  // Button,
  IconButton,
  InputLabel,
  Link,
  Typography,
  Alert,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { Context } from '../../context/Context';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';
import clsx from 'clsx';
import LogoSign from '../../assets/Logologo.png';
import bg1 from '../../assets/bgImages/01.jpg';
import bg2 from '../../assets/bgImages/02.jpg';
// import { signInPanel } from '../../graphql/queries/auth/auth';
// import { useActions } from 'store/actions';
// import { useLazyQuery } from '@apollo/react-hooks';

import {
  SaveAction,
  TextFieldWidgetText2,
  TextFieldWidgetTextBig,
} from '../../components';
// import classes from './SignIn.module.scss';
import { BsContainer, BsRow, BsCol } from '../../layouts/components';
import viewsTexts from '../../common/viewsTexts.json';
import generalTexts from '../../common/generalTexts.json';

import useAuth from '../../customHooks/useAuth';
import { FiltersContext } from 'context';
import {
  modulesPlatform,
  arrayIdDashboards,
  arrayIdsDetail,
  arrayIdsResume,
  arrayIdsAnalysis,
  arrayIdPowerBi,
  arrayIdSchedule,
} from '../../helpers/menuModules';
import { getModulesProcessIds } from '../../helpers';
import UserProfile from '../../common/profiles.json';

const randomBackground = () => {
  const arrayBackgrounds = [bg1, bg2];
  const random = Math.floor(Math.random() * arrayBackgrounds.length);
  return arrayBackgrounds[random];
};

const useStyles = makeStyles((theme) => ({
  loginContainerStyle: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    // backgroundColor: 'yellow',
    padding: '20px 10px',
    justifyContent: 'space-between',
  },
  logoStyle: {
    width: 152,
  },
  formContainerStyle: {
    width: '50%',
    margin: '0 auto',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  footerContainerStyle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderTop: '1px solid' + theme.palette.grey[500],
    paddingTop: theme.spacing(3),
  },
  buttonContainerStyle: {
    marginTop: theme.spacing(4),
  },
  bgImagesStyle: {
    backgroundImage: `url(${randomBackground()})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    height: '100%',
  },
}));

const SignIn = (props) => {
  const context = useContext(Context);

  // const { getMyOrchardsInfo } = useContext(FiltersContext);

  // const [loadingState, setLoadingState] = useState(false);

  const [showPassword, setShowPassword] = useState(false);

  const [errorProfileState, setErrorProfileState] = useState(false);

  const { login, user, accessToken, error, busy } = useAuth();

  const theme = useTheme();

  console.log(theme, '<-------', UserProfile);
  const classes = useStyles();

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const handleClickShowPassword = () => {
    setShowPassword((prevState) => !prevState);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const onSubmit = async (values) => {
    setErrorProfileState(false);
    const { user, password } = values;
    // try {
    // 	await signInHandler({ variables: { user, password } });
    // } catch (err) {}

    try {
      await login(user, password, 'user:me');
    } catch (e) {
      // console.log(e, 'error login');
      // alert('Hubo un error' + e);
    }

    // console.log(values, 'ENVIADOS AL LOGIN');

    // context.login(
    // 	'123456',
    // 	'1',
    // 	'fullname',
    // 	// data.signInPanel.profile?.name, //ejemplo
    // 	'admin', //ejemplo
    // 	'expiration',
    // 	// data.signInPanel.profile?.id,
    // 	'idProfile',
    // 	// data.signInPanel.moduleUser
    // 	modulesIntelligence
    // );
  };

  useEffect(() => {
    console.log(user, 'llega la data RESPUESTA LOGIN');
    // ejecutar evento login

    if (user && accessToken) {
      let roleId = user.roleId.id;

      const profileId = user?.profile?.id;

      if (
        // admin panel y contador no entran
        profileId !== UserProfile.UserProfile[3].id &&
        profileId !== UserProfile.UserProfile[4].id
      ) {
        const tagManagerArgs = {
          gtmId: 'GTM-W87D5MS',
          dataLayer: {
            userId: user._id,
            clientName: user.client?.label,
            fullName: `${user.name} ${user.lastname}`,
            userName: user.username,
          },
        };
        TagManager.initialize(tagManagerArgs);

        // const menuArray =
        //   user.moduleProcess && user.moduleProcess.length > 0
        //     ? user.moduleProcess.map((module) => {
        //         const resultModulePlatform = modulesPlatform.filter(
        //           (ele) => ele.id === module.id
        //         );

        //         const { process, ...others } = module;

        //         const modulePlatform = resultModulePlatform[0];

        //         // sería para habilitar o no submenu de Clima
        //         // if (module.id === '6422f05b89487f570c713901') {
        //         //   console.log(resultModulePlatform[0], '---weather', module);

        //         //   const hasWeatherDetail =
        //         //     _.findIndex(module.process, function (p) {
        //         //       return p.id === '64232c37e948ea51537502b4';
        //         //     }) >= 0;

        //         //   modulePlatform = {
        //         //     ...modulePlatform,
        //         //     process: [
        //         //       modulePlatform.process[0],
        //         //       { ...modulePlatform.process[1], assigned: hasWeatherDetail },
        //         //     ],
        //         //   };
        //         // }

        //         return {
        //           ...modulePlatform,
        //           ...others,
        //           processBackend: process,
        //         };
        //       })
        //     : modulesPlatform;

        // console.log(menuArray, '----menu del usuario');

        const ACTUAL_MODULES = [
          '000000000000000000000000',
          '6422e4d13f15d6fddadfcb8a',
          '6478c8a3b722d8c83ac83b65',
          '651eab668dd2652ad27fc094',
          '6423302fa603384a5039a60f',
          '642333cb241198d7ea424c03',
          '64234ac2f7b53d43704e067b',
          '6422f05b89487f570c713901',
          '64234ed70ba2eaeaeafc3496',
          '64f87e1ada19593a715a6292',
          '64e62ced5c228dec08aaf0e9',
          '64ef4f50e252668d3c000048',
          '668407849f5946eca6727733',
          '669faf6703ff26455e350435',
          '66d0ca968244d5872f356a03',
          '6651eab668dd252ad28fc088', // planf cosecha prueba 3
        ];

        const PRODUCTION_MODULES = [
          '000000000000000000000000', // menu
          '6422e4d13f15d6fddadfcb8a', // biblioteca
          '6478c8a3b722d8c83ac83b65', // análisis
          '651eab668dd2652ad27fc094', // pla. cosecha
          //'6423302fa603384a5039a60f', // huerto
          // '642333cb241198d7ea424c03', // poda
          '64234ac2f7b53d43704e067b', // conteo
          '6422f05b89487f570c713901', // clima
          '64234ed70ba2eaeaeafc3496', // muestreo
          '64f87e1ada19593a715a6292', // usabilidad,
          // '64e62ced5c228dec08aaf0e9', // dashboard estimativo
          // '64ef4f50e252668d3c000048', // dashboard rendimiento
          // '668407849f5946eca6727733', // powerbi
        ];

        let ACTIVE_MODULES = ACTUAL_MODULES;

        // dejar solo los modulos que tiene ACTUAL_MODULES
        const modulesFiltered = modulesPlatform.filter((module) =>
          ACTIVE_MODULES.includes(module.id)
        );

        // 1-obtengo todos los ID asignados
        // console.log(getModulesProcessIds(user.moduleProcess), '-------');
        const arrayAsignedIds = getModulesProcessIds(user.moduleProcess);

        // PARA SUMAR LOS ID POWERBI A garcesfruit
        // if (user.username === 'garcesfruit2' || user.username === 'jcalvo') {
        //   //   // id: '669fae6303ff26455e350435',
        //   //   // arrayAsignedIds.push('669fae6303ff26455e350432','669fae6303ff26455e350435');
        //   //   // arrayAsignedIds.push('66d870bd0d68a1e3899b132b');
        //   //   // arrayAsignedIds.push('66cf71cbb8426ad52cfcba51');
        //   arrayAsignedIds.push(
        //     '6651eab668dd252ad28fc089',
        //     '6651eab668dd252ad28fc090',
        //     '6651eab668dd252ad28fc091',
        //     '6651eab668dd252ad28fc092',
        //     '6651eab668dd252ad28fc088'
        //   ); // planf cosecha prueba
        // }

        // console.log(
        //   arrayAsignedIds,
        //   'arrayAsignedIds',
        //   modulesFiltered,
        //   '-------------modulesFiltered'
        // );

        let menuAsigned;

        if (arrayAsignedIds.length) {
          // 2-recorro menu plataforma
          // revisando si el id está en los asignados
          menuAsigned = modulesFiltered.map((module) => {
            if (!module.subMenu) {
              const moduleExist = arrayAsignedIds.includes(module.id);

              const resultModuleBackend = user.moduleProcess.filter(
                (ele) => ele.id === module.id
              );

              const { process = [], ...others } =
                resultModuleBackend?.[0] || {};

              // console.log('sin subs', module, others);

              return {
                ...module,
                ...others,
                namePrint: module.namePrint,
                processBackend: process,
                // assigned: moduleExist,
                // TODO: por ahora se asigna true para que se vea el menu
                assigned:
                  module.id === '000000000000000000000000' ? true : moduleExist,
              };
            } else {
              // CON SUBMENU
              let moduleExist = false;
              // tiene submenu
              let processesModulesDash;

              moduleExist = arrayAsignedIds.includes(module.id);

              const resultModuleBackend = user.moduleProcess.filter(
                (ele) => ele.id === module.id
              );

              const { process = [], ...others } =
                resultModuleBackend?.[0] || {};

              const processAssigned = module.process.map((process) => {
                let processExist = false;
                // si es weather, revisa por cada sub del menu
                if (module.id === '6422f05b89487f570c713901') {
                  // console.log('entra con', process, arrayAsignedIds);

                  // resume
                  if (process.id === '6422f73bdb22839ba692e0b6') {
                    for (const weatherProcessId of arrayIdsResume) {
                      if (arrayAsignedIds.includes(weatherProcessId)) {
                        processExist = true;
                        break;
                      }
                    }
                    // detail
                  } else {
                    for (const weatherProcessId of arrayIdsDetail) {
                      if (arrayAsignedIds.includes(weatherProcessId)) {
                        processExist = true;
                        break;
                      }
                    }
                  }
                } else {
                  processExist = arrayAsignedIds.includes(process.id);
                }

                return {
                  ...process,
                  assigned: processExist,
                };
              });

              // sin tiene procesos activos le inhabilita el módulo
              const hasAssignedProcess = processAssigned.filter(
                (process) => process.assigned === true
              );

              return {
                ...module,
                ...others,
                namePrint: module.namePrint,
                href: hasAssignedProcess[0]?.href || module.href,
                processBackend: process.length ? process : processesModulesDash,
                process: processAssigned,
                assigned: hasAssignedProcess.length ? moduleExist : false,
              };
            }
          });
        } else {
          // menuAsigned = modulesFiltered;
          menuAsigned = [];
        }

        let menuUser = menuAsigned;

        // console.log(menuUser, '-----menuUser');

        const initialMenuItem = menuUser.filter((ele) => ele.assigned)[0];

        const initialRoute =
          initialMenuItem?.process[0]?.href || initialMenuItem?.href || 'home';
        console.log(initialRoute, '-initialRoute');

        // const initialRoute = '/desktop-dashboard';

        context.login(
          accessToken,
          user._id,
          user.name,
          user.lastname,
          user.username,
          user.profile, // ejemplo
          user.profile?.id,
          user.orchard,
          menuUser,
          user?.client,
          initialRoute
        );
      } else {
        setErrorProfileState(true);
      }
    }
  }, [user, accessToken, error]);

  useEffect(() => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'logOut',
      userId: '',
      clientName: '',
      fullName: '',
      userName: '',
    });
  }, []);

  return (
    <BsContainer fluid class='h-100' style={{ height: '100%' }}>
      <BsRow class='h-100'>
        <BsCol class='col-12 col-md-8 col-lg-7'>
          <div className={classes.loginContainerStyle}>
            <div>
              <img
                src={LogoSign}
                alt='analytics'
                className={clsx('mb-4 mt-3 img-fluid', classes.logoStyle)}
              />
            </div>
            <div className={classes.formContainerStyle}>
              <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
                <Typography variant='h2' gutterBottom sx={{ fontWeight: 700 }}>
                  {viewsTexts.titles.signin.title}
                </Typography>
                <Typography variant='h5'>
                  {viewsTexts.titles.signin.sugestion}
                </Typography>
                <div className='mt-5 mt-md-4'>
                  <TextFieldWidgetText2
                    name='user'
                    display='vertical'
                    labeltext=''
                    placeholder='Nombre usuario o Email'
                    req
                    defaultValue=''
                    className='mb-2'
                    disabled={busy}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    // InputProps={{
                    //   endAdornment: (
                    //     <InputAdornment position='end'>Kg</InputAdornment>
                    //   ),
                    // }}
                    InputProps={{
                      sx: {
                        borderRadius: 40,
                      },
                    }}
                    control={control}
                    errors={errors.user}
                    // sx={{ borderRadius: 40 }}
                  />
                  <TextFieldWidgetText2
                    name='password'
                    display='vertical'
                    type={showPassword ? 'text' : 'password'}
                    labeltext=''
                    placeholder='Contraseña'
                    req
                    defaultValue=''
                    disabled={busy}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      sx: {
                        borderRadius: 40,
                      },
                      endAdornment: (
                        <InputAdornment position='end'>
                          <IconButton
                            aria-label='toggle password visibility'
                            color='default'
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    control={control}
                    errors={errors.password}
                  />
                  <div className='text-right mt-2'>
                    <Typography>
                      <Link
                        color='primary'
                        component={RouterLink}
                        to='/recovery-password'
                        variant='body2'
                      >
                        {generalTexts.textRecoveryPassword}
                      </Link>
                    </Typography>
                  </div>
                  <div className={classes.buttonContainerStyle}>
                    <SaveAction
                      title='Ingresar'
                      color='secondary'
                      size='large'
                      className='bigButton'
                      fullWidth={true}
                      variant='contained'
                      messageType='add'
                      mutationLoading={busy}
                      success={false}
                      sx={{
                        borderRadius: 40,
                      }}
                    />
                  </div>
                </div>
                {error && (
                  <div className='text-center mt-3'>
                    <Alert severity='error' variant='filled'>
                      Usuario o contraseña incorrectos.
                    </Alert>
                  </div>
                )}
                {errorProfileState && (
                  <div className='text-center'>
                    <Alert severity='error' variant='filled'>
                      Perfil de usuario incorrecto.
                    </Alert>
                  </div>
                )}
              </form>
            </div>
            <div className={classes.footerContainerStyle}>
              <Typography variant='body1'>¿No tienes cuenta?</Typography>
              <Typography>
                <Link
                  color='primary'
                  variant='body2'
                  sx={{ ml: 2, textDecoration: 'none' }}
                >
                  contáctanos
                </Link>
              </Typography>
            </div>
          </div>
        </BsCol>
        <BsCol class='col pr-0 d-none d-md-block'>
          <div className={classes.bgImagesStyle}></div>
        </BsCol>
      </BsRow>
    </BsContainer>
  );
};

export default withRouter(SignIn);
