import React, { useContext, useEffect, useState } from 'react';
import { Route } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import { getFirestore, collection, onSnapshot } from 'firebase/firestore';
import { FiltersContext } from '../../context';
import { makeStyles } from '@mui/styles';
import ErrorView from '../../ErrorView';
import { LoadingWidget } from '../../components';
import { Maintenance } from '../../views';

const useStyles = makeStyles((theme) => ({
  loadingContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
  },
}));

const RouteWithLayout = (props) => {
  const {
    layout: Layout,
    component: Component,
    flex,
    moduleId = '',
    ...rest
  } = props;

  // ahora recibe flex como prop para poder hacer que el contenido del componente se muestre block o flex

  const {
    state: { isLoading },
  } = useContext(FiltersContext);

  const classes = useStyles();

  // console.log('RouteWithLayout', isLoading);

  const [dataMaintenanceState, setDataMaintenanceState] = useState([]);

  const [maintenanceModeState, setMaintenanceModeState] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const db = getFirestore();
      const mantenimientoCollection = collection(db, 'Mantenimiento');
      const unsubscribe = onSnapshot(mantenimientoCollection, (snapshot) => {
        const data = [];
        snapshot.forEach((doc) => {
          data.push({
            id: doc.id,
            ...doc.data(),
          });
        });
        setDataMaintenanceState(data);
      });

      return () => {
        // Unsubscribe from the snapshot listener when the component unmounts
        unsubscribe();
      };
    };
    fetchData();
  }, []);

  const errorHandler = (error, errorInfo) => {
    console.log('//*--------error general: ', error, errorInfo);
  };

  useEffect(() => {
    if (dataMaintenanceState) {
      // console.log('dataMaintenanceState', dataMaintenanceState);
      const filterData = dataMaintenanceState.find(
        (item) => item.name === 'plataformaWeb'
      );

      // console.log('filterData', filterData);

      if (filterData) {
        if (filterData.status === true) {
          if (location.pathname !== '/maintenance') {
            setMaintenanceModeState(true);
          }
        } else {
          setMaintenanceModeState(false);
        }
      }
    }
  }, [dataMaintenanceState]);

  if (isLoading) {
    return (
      <div className={classes.loadingContainer}>
        <LoadingWidget />
      </div>
    );
  }

  if (maintenanceModeState) {
    return (
      <div className={classes.loadingContainer}>
        <Maintenance
          status={maintenanceModeState}
          dataMaintenance={dataMaintenanceState.find(
            (item) => item.name === 'plataformaWeb'
          )}
        />
      </div>
    );
  }

  return (
    <Route
      {...rest}
      render={(matchProps) => (
        <ErrorBoundary FallbackComponent={ErrorView} onError={errorHandler}>
          <Layout flex={flex} moduleId={moduleId}>
            <Component {...matchProps} />
          </Layout>
        </ErrorBoundary>
      )}
    />
  );
};

export default RouteWithLayout;
